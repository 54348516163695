@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;900&display=swap");

$primary-blue: #3a5edd;
$primary-green: #2eae53;
$content-font-size: 17px;
$subtitle-font-size: 20px;
$white: #ffffff;
$black: #000000;

* {
    margin: 0;
    padding: 0;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #7c7979 #d9d9d9;
}

*::-webkit-scrollbar {
    width: 20px;
}

*::-webkit-scrollbar-track {
    background: #d9d9d9;
}

*::-webkit-scrollbar-thumb {
    background-color: #7c7979;
    border-radius: 20px;
    border: 3px solid #d9d9d9;
}

body {
    background-color: #ffffff;
    font-family: "Nunito Sans", sans-serif;
    font-weight: light;
    height: 100vh;
}

.input-div {
    position: relative;
    z-index: 10;
    display: flex;
    background-color: $white;
}

.sort-p {
    color:#000000;
}

.sort-button:hover {
    color:#2eae53;
    height: 25px;
    width: 70px;
    border-radius: 3px;
}

Button {
    height: 40px;
}

.delta-posicao {
    display: none;
}

.container {
    overflow-y: scroll;
    max-height: 440px;
    background: $white;
    font-size: $content-font-size;
    color: rgb(44, 44, 44);
    margin: 29px;
    padding-left: 70px;
    margin-top: -12px;
}

.container-alunos-cand ul {
    height: 100px;
    display: inline-grid;
    grid-template-columns: 550px;
    background-color: #f5f5f5;
    border-radius: 5px;
    border: 1px solid #f5f5f5;
    color: rgb(44, 44, 44);
    font-size: 12px;
    list-style: none;
    margin-top: 15px;
    margin-left: 20px;
    overflow-y: hidden;
}

.loading-alunos-list {
    width: 86vw;
    height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-x: hidden;
}

.cand-img {
    height: 65px;
    width: 65px;
    border-radius: 50%;
    margin-left: 10px;
    margin-top: 8px;
    overflow: hidden;
    object-fit: cover;
    border: 2px solid #38e069;
}

h7 {
    font-weight: 900;
}

h8 {
    font-weight: 300;
}

h4 {
    margin-top: 0px;
}

.li-profile {
    display: inline-flex;
}

.cand-details {
    display: flex;
    flex-direction: column;
    margin-top: -65px;
    margin-left: 85px;
}

.cand-information {
    display: flex;
    flex-direction: row;
    gap: 7px;
    margin-top: -20px;
    margin-left: 280px;
}

.cand-more-information {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: -50px;
    margin-left: 265px;
}

.search-bar {
    display: flex;
    justify-content: left;
    color: $primary-blue;
    margin-left: 15px;
    margin-top: 10px;
}

.search-icon {
    position: relative;
    z-index: 10;
    display: flex;
    margin-left: -30px;
    margin-top: 10px;
    color: $primary-blue;
}

input {
    position: relative;
    width: 515px;
    height: 35px;
    background-color: white;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif;
    color: $primary-blue;
    text-align: left;
    border: 1px solid $primary-blue;
    border-radius: 20px;
    padding-left: 10px;
    margin-left: 136px;
    margin-bottom: 20px;
    overflow: hidden;
}

textarea:focus,
input:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.cand-bar-information {
    width: 250px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: -72px;
    margin-left: 292px;
}

.apuracao-progress .apuracao-progress-bar {
    align-items: right;
    position: absolute;
    width: auto;
    height: 100%;
    background-color: #38e069;
}

.loading-resultados {
    position: absolute;
    z-index: -5000;
}

@keyframes apuracao-progress-animation {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@media (min-width: 1920px) and (max-width: 2800px) {
    .container {
        overflow-y: scroll;
        max-height: 690px;
        background: $white;
        font-size: $content-font-size;
        color: rgb(44, 44, 44);
        padding-left: 70px;
        height: 750px;
    }

    .input-div {
        margin-bottom: -10px;
    }

    .loading-resultados {
        position: absolute;
        z-index: -5000;
        margin-left: 300px;
        margin-top: 30px;
    }

    .container-alunos-cand ul {
        width: 540px;
        height: 90px;
        display: inline-grid;
        grid-template-columns: 540px;
        background-color: #f5f5f5;
        border-radius: 5px;
        border: 1px solid #f5f5f5;
        color: rgb(44, 44, 44);
        font-size: 11px;
        list-style: none;
        margin-top: 14px;
        margin-left: 30px;
    }

    .cand-details {
        display: flex;
        flex-direction: column;
        margin-top: -65px;
        margin-left: px;
    }

    .cand-information {
        display: flex;
        flex-direction: row;
        gap: 7px;
        margin-top: -22px;
        margin-left: 300px;
    }

    .cand-more-information {
        display: flex;
        flex-direction: row;
        gap: 5px;
        margin-top: -50px;
        margin-left: 256px;
    }

    .cand-bar-information {
        width: 250px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        margin-top: -70px;
        margin-left: 280px;
    }

    .apuracao-progress {
        margin-top: -1px;
        width: 250px;
        position: relative;
        height: 5px;
        background-color: #d9d9d9;
    }

    .apuracao-progress .apuracao-progress-bar {
        text-align: right;
        align-items: right;
        position: absolute;
        width: auto;
        height: 100%;
        background-color: #38e069;
    }

    .cand-img {
        height: 65px;
        width: 65px;
        border-radius: 50%;
        margin-left: 10px;
        margin-top: 15px;
        overflow: hidden;
        object-fit: cover;
        border: 2px solid #38e069;
    }

    input {
        position: relative;
        width: 515px;
        height: 35px;
        background-color: white;
        font-size: 14px;
        font-family: "Nunito Sans", sans-serif;
        color: $primary-blue;
        text-align: left;
        border: 1px solid $primary-blue;
        border-radius: 20px;
        padding-left: 10px;
        margin-top: 20px;
        margin-left: 155px;
    }
    .search-icon {
        position: relative;
        z-index: 10;
        display: flex;
        margin-left: -30px;
        margin-top: 30px;
        color: $primary-blue;
    }
    .sort-p {
        color:#000000;
        margin-top: 8px;
    }
    .sort-div {
        margin-top: 3px;
    }
}

@media (min-width: 1600px) and (max-width: 1910px) {
    .container {
        overflow-y: scroll;
        max-height: 625px;
        background: $white;
        font-size: $content-font-size;
        color: rgb(44, 44, 44);
        margin: 35px;
        padding-left: 70px;
        margin-top: -4px;
        height: 625px;
    }

    .loading-resultados {
        position: absolute;
        z-index: -5000;
    }

    .container-alunos-cand ul {
        width: 570px;
        height: 100px;
        display: inline-grid;
        grid-template-columns: 540px;
        background-color: #f5f5f5;
        border-radius: 5px;
        border: 1px solid #f5f5f5;
        color: rgb(44, 44, 44);
        font-size: 11px;
        list-style: none;
        margin-top: 14px;
        margin-left: 30px;
    }

    .cand-details {
        display: flex;
        flex-direction: column;
        margin-top: -65px;
        margin-left: px;
    }

    .cand-information {
        display: flex;
        flex-direction: row;
        gap: 7px;
        margin-top: -22px;
        margin-left: 265px;
    }

    .cand-more-information {
        display: flex;
        flex-direction: row;
        gap: 5px;
        margin-top: -52px;
        margin-left: 275px;
    }

    .cand-bar-information {
        width: 250px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        margin-top: -70px;
        margin-left: 280px;
    }

    .apuracao-progress {
        margin-top: -1px;
        width: 250px;
        position: relative;
        height: 5px;
        background-color: #d9d9d9;
    }

    .apuracao-progress .apuracao-progress-bar {
        text-align: right;
        align-items: right;
        position: absolute;
        width: auto;
        height: 100%;
        background-color: #38e069;
    }

    .cand-img {
        height: 65px;
        width: 65px;
        border-radius: 50%;
        margin-left: 10px;
        margin-top: 15px;
        overflow: hidden;
        object-fit: cover;
        border: 2px solid #38e069;
    }

    input {
        position: relative;
        width: 515px;
        height: 35px;
        background-color: white;
        font-size: 14px;
        font-family: "Nunito Sans", sans-serif;
        color: $primary-blue;
        text-align: left;
        border: 1px solid $primary-blue;
        border-radius: 20px;
        padding-left: 10px;
        margin-top: 20px;
        margin-left: 155px;
    }
    .search-icon {
        position: relative;
        z-index: 10;
        display: flex;
        margin-left: -30px;
        margin-top: 30px;
        color: $primary-blue;
    }
    .sort-p {
        color:#000000;
        margin-top: 4px;
    }
}

@media (min-width: 1390px) and (max-width: 1590px) {
    .container {
        overflow-y: scroll;
        max-height: 560px;
        background: $white;
        font-size: $content-font-size;
        color: rgb(44, 44, 44);
        margin: 35px;
        padding-left: 70px;
        margin-top: -4px;
        height: 620px;
    }

    .loading-resultados {
        position: absolute;
        z-index: -5000;
    }

    .container-alunos-cand ul {
        width: 590px;
        height: 90px;
        display: inline-grid;
        grid-template-columns: 547px;
        background-color: #f5f5f5;
        border-radius: 5px;
        border: 1px solid #f5f5f5;
        color: rgb(44, 44, 44);
        font-size: 11px;
        list-style: none;
        margin-top: 14px;
        margin-left: 30px;
    }

    .cand-details {
        display: flex;
        flex-direction: column;
        margin-top: -65px;
        margin-left: px;
    }

    .cand-information {
        display: flex;
        flex-direction: row;
        gap: 7px;
        margin-top: -22px;
        margin-left: 300px;
    }

    .cand-more-information {
        display: flex;
        flex-direction: row;
        gap: 5px;
        margin-top: -52px;
        margin-left: 265px;
    }

    .cand-bar-information {
        width: 250px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        margin-top: -70px;
        margin-left: 280px;
    }

    .apuracao-progress {
        margin-top: -1px;
        width: 250px;
        position: relative;
        height: 5px;
        background-color: #d9d9d9;
    }

    .apuracao-progress .apuracao-progress-bar {
        text-align: right;
        align-items: right;
        position: absolute;
        width: auto;
        height: 100%;
        background-color: #38e069;
    }

    .cand-img {
        height: 65px;
        width: 65px;
        border-radius: 50%;
        margin-left: 10px;
        margin-top: 10px;
        overflow: hidden;
        object-fit: cover;
        border: 2px solid #38e069;
    }
    input {
        position: relative;
        width: 515px;
        height: 35px;
        background-color: white;
        font-size: 14px;
        font-family: "Nunito Sans", sans-serif;
        color: $primary-blue;
        text-align: left;
        border: 1px solid $primary-blue;
        border-radius: 20px;
        padding-left: 10px;
        margin-top: 20px;
        margin-left: 155px;
    }
    .search-icon {
        position: relative;
        z-index: 10;
        display: flex;
        margin-left: -30px;
        margin-top: 30px;
        color: $primary-blue;
    }

    .sort-p {
        color:#000000;
        margin-top: 4px;
    }
}

@media (min-width: 1100px) and (max-width: 1390px) {
    .container {
        overflow-y: scroll;
        max-height: 380px;
        background: $white;
        font-size: $content-font-size;
        color: rgb(44, 44, 44);
        margin: 35px;
        padding-left: 70px;
        margin-top: -17px;
        height: 490px;
    }

    .loading-resultados {
        position: absolute;
        z-index: -5000;
    }
    .container-alunos-cand ul {
        width: 534px;
        height: 88px;
        overflow-x: hidden;
        display: inline-grid;
        grid-template-columns: 540px;
        background-color: #f5f5f5;
        border-radius: 5px;
        border: 1px solid #f5f5f5;
        color: rgb(44, 44, 44);
        font-size: 11px;
        list-style: none;
        margin-top: 14px;
        margin-left: 34px;
    }

    .cand-details {
        display: flex;
        flex-direction: column;
        margin-top: -65px;
    }

    .cand-information {
        display: flex;
        flex-direction: row;
        gap: 7px;
        margin-top: -22px;
        margin-left: 300px;
    }

    .cand-more-information {
        display: flex;
        flex-direction: row;
        gap: 5px;
        margin-top: -49px;
        margin-left: 245px;
    }

    .cand-bar-information {
        width: 250px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        margin-top: -70px;
        margin-left: 280px;
    }

    .apuracao-progress {
        margin-top: -1px;
        width: 250px;
        position: relative;
        height: 5px;
        background-color: #d9d9d9;
    }

    .apuracao-progress .apuracao-progress-bar {
        text-align: right;
        align-items: right;
        position: absolute;
        width: auto;
        height: 100%;
        background-color: #38e069;
    }

    .cand-img {
        height: 65px;
        width: 65px;
        border-radius: 50%;
        margin-left: 10px;
        border: 2px solid #38e069;
        margin-top: 9px;
    }

    .input-div {
        position: relative;
        z-index: 10;
        display: flex;
        background-color: $white;
    }

    input {
        position: relative;
        width: 515px;
        height: 35px;
        background-color: white;
        font-size: 14px;
        font-family: "Nunito Sans", sans-serif;
        color: $primary-blue;
        text-align: left;
        border: 1px solid $primary-blue;
        border-radius: 20px;
        padding-left: 16px;
        margin-top: 20px;
        margin-left: 155px;
        margin-bottom: 10px;
    }
    .search-icon {
        position: relative;
        z-index: 10;
        display: flex;
        margin-left: -30px;
        margin-top: 30px;
        color: $primary-blue;
    }

    .sort-p {
        color:#000000;
        margin-top: 7px;
    }
    
}
