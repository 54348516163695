.subtitles {
    display: inline-block;
    font-size: 10px;
    padding-top: 20px;
    margin-left: 150px;
    width: 520px;
}

.subtitles h2 {
    display: flex;
    flex-direction: row;
}

.subtitles-details {
    display: inline;
    justify-content: left;
}

.legenda img {
    display: flex;
    margin-left: 550px;
    margin-top: -35px;
}

.progress {
    height: 10px;
}

@media (min-width: 1920px) and (max-width: 2800px) {
    .subtitles {
        display: inline-block;
        justify-content: center;
        font-size: 10px;
        padding-top: 15px;
        margin-left: 370px;
        width: 520px;
    }

    .legenda img {
        display: flex;
        justify-content: right;
        margin-top: -35px;
    }
}
