@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;900&display=swap");

$text-color: rgb(24, 24, 24);
$primary-blue: #3a5edd;
$primary-green: #2eae53;
$content-font-size: 17px;
$subtitle-font-size: 20px;
$white: #ffffff;
$black: #000000;

/* MAPA */
.box-mapa {
  position: relative;
  float: left;
  padding: 70px 10px 50px 10px;
}

#map {
  display: flex;
  transform: scale(1.2);
  transform: scale(1.3);
  display: table !important;
  margin: 0 auto;
  float: left;
  margin-left: -60px;
}

#map .state {
  cursor: pointer;
}

#map .state .shape {
  cursor: pointer;
  -width: 0;
}

#map .state .label_icon_state {
  fill: #fff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 13px;
  line-height: 12px;
  color: $black;
}

#map .state .label_state {
  display: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 13px;
  line-height: 16px;
  color: $black;
}

#map .model-davi .state:hover .label_state {
  display: block;
  fill: rgb(10, 10, 10) !important;
}

#map .model-davi .state .shape {
  fill: rgb(216, 216, 216);
}

#map .model-davi .state:hover .shape {
  fill: $primary-blue !important;
}

#map .model-davi .state .icon_state {
  fill: #38e069;
}

#map .model-davi .state:hover .icon_state {
  fill: #38e069;
}

#map .model-davi .state .icon_state + .label_icon_state {
  fill: #fff;
}

#map .model-davi .state .label_icon_state {
  fill: #666;
}

#map .model-davi .state:hover .label_icon_state {
  fill: #fff;
}

#map .model-davi .state .label_state {
  fill: #666;
  display: none;
}

#map .model-davi .state .shape {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#map .model-davi .state:hover .shape {
  fill: #0288d1;
}

.parca {
  position: relative;
  float: left;
  padding: 0 0 0 15%;
}

.parca select {
  display: none;
  margin: 0 auto;
  width: 230px;
  border: 1px solid $white;
  height: 40px;
  padding: 9px;
  border-radius: 0;
}

.mapa-wrapper h1 {
  color: rgb(44, 44, 44);
  font-size: 17px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.parca .estado {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgb(196, 196, 196);
  font-size: 12px;
  color: $black;
  position: absolute;
  list-style: none;
  opacity: 0;
  border-radius: 4px;
  visibility: visible;
  padding: 10px;
  left: 0;
  right: 0;
  min-width: 450px;
  max-height: 280px;
  display: table;
  margin: 59px 0 0 92px;
}

.parca .estado h3 {
  margin-top: 0;
  display: block;
  background-color: #f2f2f2;
  padding: 10px;
  text-transform: uppercase;
  color: rgb(22, 22, 22);
  margin-bottom: 10px;
}

.parca .estado ul {
  text-align: center;
  position: relative;
  z-index: 10;
  max-height: 400px;
  list-style: none;
  background-color: #ffffff;
  overflow-y: scroll;
  border-radius: 10px;
  overflow-x: hidden;
}

.parca .estado li {
  display: inline-grid;
  background-color: #f2f2f2;
  border-radius: 5px;
  grid-template-columns: 186px;
  padding-right: 10px;
  margin-top: 10px;
  margin-left: 6px;
}

.eleito-img {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  margin-left: 70px;
  margin-top: 15px;
  overflow: hidden;
  object-fit: cover;
  border: 2px solid #38e069;
}

.eleito-icone {
  margin-left: 52px;
  margin-bottom: 15px;
}

.eleito-votos {
  display: flex;
  gap: 6px;
  margin-top: -2px;
  margin-left: 20px;
  color: black;
  font-size: 12px;
  margin-bottom: 10px;
}

.li-cargo {
  font-weight: bold;
}

.parca #box_mg.estado {
  opacity: 1;
  visibility: visible;
}

.label_icon_state_ba_circle {
  font-family: "Nunito Sans", sans-serif;
  font-size: 12px;
  background-color: #2eae53;
}

@media (max-width: 1199px) {
  #map {
    transform: scale(1);
  }
}
@media (max-width: 991px) {
  .parceiros .two .parca .estado {
    display: block;
    margin: 45px auto;
    float: none;
    position: absolute;
    left: 0;
    right: 0;
    min-width: 160px;
    max-width: 306px;
  }

  .box-mapa {
    position: relative;
    top: 0;
    left: initial;
    display: table;
    margin: 0 auto;
  }
}
@media (max-width: 500px) {
  .box-mapa {
    display: none;
  }

  .parca select {
    display: block;
  }
}

@media (min-width: 1100px) and (max-width: 1390px) {
  .eleito-img {
    height: 65px;
    width: 65px;
    border-radius: 50%;
    margin-left: 65px;
    margin-bottom: 10px;
    margin-top: 10px;
    overflow: hidden;
    object-fit: cover;
    border: 2px solid #38e069;
  }
}

@media (min-width: 1920px) and (max-width: 2800px) {
  .box-mapa {
    position: relative;
    float: center;
    padding: 70px 80px 50px 10px;
  }

  #map {
    display: flex;
    transform: scale(1.2);
    transform: scale(1.4);
    display: table !important;
    margin: 0 auto;
    float: left;
    margin-left: 170px;
  }
}

@media (min-width: 1600px) and (max-width: 2800px) {
  .box-mapa {
    position: relative;
    float: center;
    padding: 80px 50px 40px 10px;
  }

  #map {
    display: flex;
    transform: scale(1.2);
    transform: scale(1.4);
    display: table !important;
    margin: 0 auto;
    float: left;
    margin-left: 140px;
    margin-top: 20px;
  }

  .parca .estado {
    height: 380px;
  }
}
