.description-wrapper {
    position: relative;
}

.description {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-left: 50px;
    margin-top: 10px;
}

.description .description-boxes {
    display: inline-block;
    font-size: 0.8rem;
}

.description-boxes img {
    margin-top: 4px;
}
