@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;900&display=swap");

$text-color: rgb(24, 24, 24);
$primary-blue: #3a5edd;
$primary-green: #2eae53;
$content-font-size: 17px;
$subtitle-font-size: 20px;
$white: #ffffff;
$black: #000000;

.search-eleitos {
  display: none;
}

.container-eleitos {
  width: 583px;
  height: 440px;
  background: transparent;
  font-size: 17px;
  color: rgb(24, 24, 24);
  margin: 37px;
  margin-left: 106px;
  margin-top: -462px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.container-eleitos-cand ul {
  height: 100px;
  display: inline-grid;
  grid-template-columns: 534px;
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  color: $text-color;
  font-size: 12px;
  list-style: none;
  margin-top: 10px;
  overflow: hidden;
}

.li-profile-eleitos {
  width: 200px;
}

.cand-details {
  display: flex;
  flex-direction: column;
  margin-top: -65px;
  margin-left: px;
}

.cand-information {
  display: flex;
  flex-direction: row;
  gap: 7px;
  margin-top: -22px;
  margin-left: 300px;
}

.eleito-more-information {
  display: flex;
  flex-direction: row;
  gap: 7px;
  margin-top: -45px;
  margin-left: 275px;
}

.result-cards {
  position: absolute;
  background-color: #f5f5f5;
  border: 1px solid green;
  width: 100px;
  height: 100px;
  margin-left: 700px;
  display: inline-grid;
  grid-template-columns: 100px;
}

body {
  width: 100%;
}

/* MAPA */
.box-mapa {
  position: relative;
  z-index: 1;
  float: center;
  padding: 20px 0 0 15px;
  margin-left: 250px;
}

#map {
  display: none;
  transform: scale(1.2);
  transform: scale(1);
  display: table !important;
  margin: 0 auto;
  float: center;
}

#map .state {
  cursor: pointer;
}

#map .state .shape {
  cursor: pointer;
  -width: 0;
}

#map .state .label_icon_state {
  fill: #fff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 11px;
  line-height: 12px;
  font-weight: normal;
}

#map .state .label_state {
  display: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
}

#map .model-davi .state:hover .label_state {
  display: block;
  fill: #fff !important;
}

#map .model-davi .state .shape {
  fill: #ddd;
}

#map .model-davi .state:hover .shape {
  fill: #3a5edd !important;
}

#map .model-davi .state .icon_state {
  fill: #2eae53;
}

#map .model-davi .state:hover .icon_state {
  fill: #3a5edd;
}

#map .model-davi .state .icon_state + .label_icon_state {
  fill: #fff;
}

#map .model-davi .state .label_icon_state {
  fill: #fff;
}

#map .model-davi .state:hover .label_icon_state {
  fill: #fff;
}

#map .model-davi .state .label_state {
  fill: #fff;
  display: none;
}

#map .model-davi .state .shape {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#map .model-davi .state:hover .shape {
  fill: #0288d1;
}

.parca {
  position: relative;
  float: left;
  padding: 0 0 0 16%;
}

.parca select {
  display: none;
  margin: 0 auto;
  width: 230px;
  border: 1px solid #d9d9d9;
  height: 40px;
  padding: 9px;
  border-radius: 0;
}

.parca .estado {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid #ddd;
  position: absolute;
  margin-top: 45px;
  opacity: 0;
  border-radius: 5px;
  visibility: visible;
  padding: 10px;
  left: 0;
  right: 0;
  min-width: 330px;
  min-height: 230px;
  display: table;
  margin: 57px 0 0 80px;
  box-shadow: 0 1px 10px 0px rgba(0, 0, 0, 0.3);
}

.parca .estado h3 {
  margin-top: 0;
  display: block;
  background-color: #d9d9d9;
  padding: 10px;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 100px;
}

.parca .estado ul {
  padding-left: 0px;
}

.parca .estado ul li {
  line-height: 24px;
}

.parca #box_mg.estado {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1199px) {
  #map {
    transform: scale(1);
  }
}
@media (max-width: 991px) {
  .parceiros .two .parca .estado {
    display: block;
    margin: 45px auto;
    float: none;
    position: absolute;
    left: 0;
    right: 0;
    min-width: 160px;
    max-width: 306px;
  }

  .box-mapa {
    position: relative;
    top: 0;
    left: initial;
    display: table;
    margin: 0 auto;
  }
}
@media (max-width: 500px) {
  .box-mapa {
    display: none;
  }

  .parca select {
    display: block;
  }
}

@media (min-width: 1920px) and (max-width: 2800px) {

  .container-eleitos {
    width: 609px;
    height: 447px;
    overflow-y: scroll;
    background: transparent;
    font-size: 17px;
    color: rgb(24, 24, 24);
    margin: 35px;
    margin-top: 35px;
    margin-left: 35px;
    margin-left: 300px;
    padding-right: 10px;
    margin-top: -475px;
    overflow-x: hidden;
  }

  .container-eleitos-cand ul {
    height: 100px;
    display: inline-grid;
    grid-template-columns: 572px;
    background-color: #f5f5f5;
    border-radius: 5px;
    border: 1px solid #f5f5f5;
    color: rgb(24, 24, 24);
    font-size: 12px;
    list-style: none;
    margin-top: 10px;
  }

  .cand-more-information {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: -53px;
    margin-left: 283px;
}
}

@media (min-width: 1600px) and (max-width: 2800px) {
  .container-eleitos {
    width: 609px;
    height: 447px;
    overflow-y: scroll;
    background: transparent;
    font-size: 17px;
    color: rgb(24, 24, 24);
    margin: 35px;
    margin-top: 35px;
    margin-left: 35px;
    margin-left: 300px;
    padding-right: 10px;
    margin-top: -475px;
    overflow-x: hidden;
  }

  .container-eleitos-cand ul {
    height: 100px;
    display: inline-grid;
    grid-template-columns: 572px;
    background-color: #f5f5f5;
    border-radius: 5px;
    border: 1px solid #f5f5f5;
    color: rgb(24, 24, 24);
    font-size: 12px;
    list-style: none;
    margin-top: 10px;
  }
}

@media (min-width: 1100px) and (max-width: 1390px) {
  .eleito-more-information {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: -49px;
    margin-left: 270px;
  }
}