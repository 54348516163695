@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;900&display=swap");

header {
    background-color: #3a5edd;
    display: flex;
    justify-content: space-around;
    height: 50px;
    padding-top: 5px;
    padding-bottom: 0.5rem;
    padding-left: 80px;
    padding-right: 120px;
}

h1 {
    font-size: 18px;
    font-weight: 100;
    color: #ffffff;
    margin-top: 14px;
}

p {
    font-size: 16px;
    font-weight: 100;
    color: #ffffff;
    margin-top: 15px;
}

p a {
    font-weight: 600;
    position: relative;
    color: #ffffff;
    text-decoration: none;
}

p a::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #38e069;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

p a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.logo-renovabr {
    height: 70px;
}
