@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;900&display=swap");

.cargos-cards {
   position: relative;
   margin-left: 690px;
   margin-top: 20px;
   margin-bottom: 20px;
   font-size: 12px;
}

.cards-b {
   font-weight: bold;
   font-size: 16px;
}

.content {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 5px;
}

.content .box {
   display: grid;
   text-align: center;
   width: 90px;
   height: 100px;
   border-radius: 5px;
   background-color: #f5f5f5;
   text-transform: uppercase;
   align-items: center;
   margin-left: 14px;
}

.box svg {
   width: 100%;
}

.content-row-2 {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 5px;
}

.content-row-2 .box {
   display: grid;
   text-align: center;
   width: 90px;
   height: 100px;
   border-radius: 5px;
   background-color: #f5f5f5;
   text-transform: uppercase;
   align-items: center;
   margin-left: 14px;
   margin-top: 10px;
}

.content-row-3 {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 5px;
}

.content-row-3 .box {
   display: grid;
   text-align: center;
   width: 90px;
   height: 100px;
   border-radius: 5px;
   background-color: #f5f5f5;
   text-transform: uppercase;
   align-items: center;
   margin-left: 14px;
   margin-top: 10px;
}

.content-row-4 {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 5px;
}

.content-row-4 .box {
   display: grid;
   text-align: center;
   width: 90px;
   height: 95px;
   border-radius: 5px;
   background-color: #f5f5f5;
   text-transform: uppercase;
   align-items: center;
   margin-left: 14px;
   margin-top: 10px;
}

// CARDS DOS GÊNEROS

.generos-cards {
   margin-left: 218px;
   margin-top: -435px;
   font-size: 11.5px;
}

.content-generos {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
}

.content-generos svg {
   width: 100%;
   margin-top: 10px;
   margin-bottom: 10px;
}

.content-eleitos-homens {
   display: inline-grid;
   grid-template-columns: 50px;
   text-align: center;
   padding-left: 20px;
   width: 300px;
   height: 100px;
   border-radius: 5px;
   background-color: #f5f5f5;
   text-transform: uppercase;
   align-items: center;
   margin-left: 14px;
   margin-top: 10px;
   line-height: 15px;
}

.content-media-idade-homens {
   width: 100px;
   text-align: center;
   margin-left: 95px;
   margin-top: -130px;
}

.content-media-votos-homens {
   width: 200px;
   text-align: center;
   margin-left: 44px;
   margin-top: -35px;
}

.content-progress-homens {
   margin-left: 225px;
   margin-top: -90px;
}

.content-eleitas-mulheres {
   display: inline-grid;
   grid-template-columns: 50px;
   text-align: center;
   padding-left: 20px;
   width: 300px;
   height: 100px;
   border-radius: 5px;
   background-color: #f5f5f5;
   text-transform: uppercase;
   align-items: center;
   margin-left: 14px;
   margin-top: 10px;
   line-height: 10px;
}

.content-media-idade-mulheres {
   text-transform: uppercase;
   width: 100px;
   text-align: center;
   margin-left: 125px;
   margin-top: -90px;
}

.content-media-votos-mulheres {
   text-transform: uppercase;
   width: 200px;
   text-align: center;
   margin-left: 75px;
   margin-top: 10px;
}

.content-progress-mulheres {
   margin-left: 260px;
   margin-top: -78px;
}

// OUTROS CARDS DE CARGOS

.card-distrital {
   display: inline;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 10px;
}

.card-distrital .box {
   display: grid;
   text-align: center;
   width: 90px;
   height: 100px;
   border-radius: 5px;
   background-color: #f5f5f5;
   text-transform: uppercase;
   align-items: center;
   margin-left: 230px;
   margin-top: 32px;
}

.card-federal {
   display: inline;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 5px;
}

.card-federal .box {
   display: grid;
   text-align: center;
   width: 90px;
   height: 100px;
   border-radius: 5px;
   background-color: #f5f5f5;
   text-transform: uppercase;
   align-items: center;
   margin-left: 345px;
   margin-top: -100px;
}

.card-senado {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 5px;
}

.card-senado .box {
   display: grid;
   text-align: center;
   width: 90px;
   height: 100px;
   border-radius: 5px;
   background-color: #f5f5f5;
   text-transform: uppercase;
   align-items: center;
   margin-left: 462px;
   margin-top: -100px;
}

.card-vice {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 5px;
}

.card-vice .box {
   display: grid;
   text-align: center;
   width: 90px;
   height: 95px;
   border-radius: 5px;
   background-color: #f5f5f5;
   text-transform: uppercase;
   align-items: center;
   margin-left: 230px;
   margin-top: 10px;
}

.card-votos-totais {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 5px;
}

.card-votos-totais .box {
   display: grid;
   text-align: center;
   width: 210px;
   height: 95px;
   border-radius: 5px;
   background-color: #f5f5f5;
   text-transform: uppercase;
   align-items: center;
   margin-left: 345px;
   margin-top: -95px;
}

button {
   font-size: 12px;
   text-transform: uppercase;
   color: #3a5edd;
   border: none;
   cursor: pointer;
   background-color: transparent;
   font-weight: inherit;
}

button:hover {
   color: #3a5edd;
}

button:disabled {
   color: rgb(44, 44, 44);
   cursor: auto;
   font-weight: inherit;
   padding-top: 10px;
}

hr {
   display: block;
   height: 1px;
   border: 0;
   border-top: 1px solid #ccc;
   padding: 0;
}

.container-resultados {
   overflow-y: scroll;
   overflow-x: hidden;
   max-height: 390px;
   background: white;
   font-size: 12px;
   color: rgb(44, 44, 44);
   padding-right: 10px;
   margin-left: -30px;
   font-family: "Nunito Sans", sans-serif;
}

.container-alunos-resultados ul {
   height: 100px;
   display: inline-grid;
   grid-template-columns: 545px;
   background-color: #f5f5f5;
   border-radius: 5px;
   border: 1px solid #f5f5f5;
   color: rgb(44, 44, 44);
   font-size: 12px;
   list-style: none;
   margin-top: 15px;
   margin-left: 17px;
   overflow-y: hidden;
   overflow-x: hidden;
}

.button-resultados-estadual {
   margin: 0;
}

@media (min-width: 1910px) and (max-width: 2800px) {
   .cargos-cards {
      position: relative;
      z-index: 400;
      margin-left: 970px;
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 12px;
   }

   .aluno-more-information {
      display: flex;
      flex-direction: row;
      gap: 5px;
      margin-top: -53px;
      margin-left: 270px;
   }

   .container-alunos-resultados ul {
    height: 100px;
    display: inline-grid;
    grid-template-columns: 532px;
    background-color: #f5f5f5;
    border-radius: 5px;
    border: 1px solid #f5f5f5;
    color: rgb(44, 44, 44);
    font-size: 12px;
    list-style: none;
    margin-top: 15px;
    margin-left: 17px;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

@media (min-width: 1600px) and (max-width: 1910px) {
   .cargos-cards {
      position: relative;
      z-index: 400;
      margin-left: 930px;
      margin-top: 45px;
      font-size: 12px;
   }
   .aluno-more-information {
      display: flex;
      flex-direction: row;
      gap: 5px;
      margin-top: -53px;
      margin-left: 270px;
   }
}

@media (min-width: 1390px) and (max-width: 1600px) {
   .cargos-cards {
      font-size: 12px;
      margin-left: 719px;
      margin-top: 46px;
      position: relative;
      z-index: 400;
   }
   .aluno-more-information {
      display: flex;
      flex-direction: row;
      gap: 5px;
      margin-top: -53px;
      margin-left: 270px;
   }
}

@media (min-width: 1100px) and (max-width: 1390px) {
      .aluno-more-information {
      display: flex;
      flex-direction: row;
      gap: 5px;
      margin-top: -53px;
      margin-left: 270px;
   }
}
